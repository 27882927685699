import { Fragment, useState, useEffect, useRef } from "react";
import { User } from "../../types";
import { RouteConstants } from "../../helpers/RouteConstants";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import PhoneInput from "react-phone-number-input";
import { Radio } from "@material-tailwind/react";
import ShowToast from "../../components/alerts/all_toasts";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { GetProp, UploadProps } from "antd";
import { StorageBox } from "../../helpers/stroage";
import axios from "axios";
//import ImgCrop from 'antd-img-crop'
import BaseService from "../../helpers/baseServices";
import { Input } from "antd";
import ProfileImageModal from "../../components/ProfileModal";
import Webcam from "react-webcam"
import { ACCOUNTS_URL } from "../../helpers/constants";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const EditPersonal = ({
  handleStepChange,
}: {
  handleStepChange: () => void;
}) => {
  const [userPersonal, setPersonalData] = useState<User | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [middle_name, setMiddleName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageModal, setImageModal] = useState(false);
  const [useCamera, setUseCamera] = useState(false); // State to toggle webcam
  const webcamRef = useRef<Webcam>(null); // Reference for the webcam
  const [photo, setPhoto] = useState<string | File | null>(null); // Store the captured photo (either string, File, or null)

  // logic to read image as
  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  // logic to varify image before its uploaded
  const beforeUpload = (file: FileType) => {
    // check if selected file is an image
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    //check if file size is less than 2megabite
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };

  // logic to change image from the file system
  const handleUploadChange: UploadProps["onChange"] = async (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  // button to click to upload image
  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  // logic to get user profile information
  const getUserData = async () => {
    setIsLoading(true);
    try {
      const res = await BaseService.account_ms_get_request(`/v1/profile`);

      setPersonalData(res.data?.payload);
      setGender(res.data?.payload?.gender);
      setIsLoading(false);
      //return res;
    } catch (e) {
      setIsLoading(false);
    }
  };

  // logic to submit edited profile values
  const handleSubmit = () => {
    // let formatIce1Phone = ice1_phone?.replace(/\s+/g, '');
    // let formatIce2Phone = ice2_phone?.replace(/\s+/g, '');

    setIsLoading(true);

    // if the value was changed, use the change value otherwise use the database value
    BaseService.put_api_auth(RouteConstants.PROFILE, {
      first_name: first_name ? first_name : userPersonal?.first_name,
      last_name: last_name ? last_name : userPersonal?.last_name,
      middle_name: middle_name ? middle_name : userPersonal?.middle_name,
      phone: userPersonal?.phone,
      gender: gender ? gender : userPersonal?.gender,
      email: email ? email : userPersonal?.email,
      ice1_phone: userPersonal?.ice1_phone,
      ice2_phone: userPersonal?.ice2_phone,
      birthdate: dob ? dob : userPersonal?.birthdate,
    })
      .then((response: any) => {
        console.log(response.data);
        ShowToast.success_toast("Profile has been updated!");
        handleStepChange();
        window.location.reload();
      })
      .catch((e: any) => {
        ShowToast.error_toast(e?.response?.data?.message || e?.message);
      })
      .finally(() => setIsLoading(false));
  };

  // ask Emmanuel Amoako
  const handleAction = async (file: any) => {
    try {
      console.log("File type:", file?.type);
      const res = await BaseService.account_ms_post_request(
        `/v1/profile/image/presigned-upload`,
        {
          mime: file?.type,
        }
      );
      if (res.data?.payload?.upload_url) {
        await axios.put(res.data?.payload?.upload_url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
      }
      window.location.reload();
    } catch (error) {
      message.error("File upload failed. Try again");
    }
    return "";
  };

  //FUNCTION TO CHOOSE PHOTO FROM GALLERY
  const changeImageHander = async (e: any) => {
    // console.log(e.target.files[0])
    setImageModal(false);
    setIsLoading(true);
    await handleAction(e.target.files[0]);
  };

  // Function to capture the image from the webcam
  const capturePhoto = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log("Image link:", imageSrc);

      if (imageSrc) {
        // Convert the base64 image to a Blob
        const response = await fetch(imageSrc); // Fetch the image data
        const blob = await response.blob(); // Create a Blob from the image data

        // Convert the Blob to a File object
        const file = new File([blob], "photo.jpg", { type: "image/jpeg" });
        console.log("Captured file type:", file.type); // Log the file type

        // Set the photo as a File object
        setPhoto(file); // Set the captured image as the profile picture

        // Now call handleAction to upload the file
        //await handleAction(file);
      }

      setUseCamera(false); // Close the camera modal
    }
  };


  const choosePhoto = async () => {
    if (photo) {
      if (photo instanceof File) {
        setImageUrl(URL.createObjectURL(photo)); // Set the captured photo as the profile image
      }
      setImageModal(false); // Close the modal after choosing the photo

      // Call handleAction to upload the photo
      await handleAction(photo); // Upload the photo
    }
  };




  


  // FUNCTION TO DELETE PROFILE PICTURE
  const removeImageHandler = async () => {
    setIsLoading(true); // Optionally show a loading state while the request is in progress

    try {
      // Get the decrypted access token
      const accessToken = StorageBox.getAccessToken();
      console.log("Decrypted Access Token:", accessToken); // Print the decrypted token

      // Make a DELETE request directly to the API
      await axios.delete(
        `${ACCOUNTS_URL}/v1/remove-profile-picture`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Include the access token in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );

      // If successful, clear the profile image on the front-end
      setImageUrl(""); // Clears the profile image from the UI
      setImageModal(false); // Closes the image modal
      console.log("Profile pic successfully removed");
      ShowToast.success_toast("Profile picture removed successfully");
      window.location.reload(); // Show success toast
    } catch (error) {
      // Handle error if the request fails
      ShowToast.error_toast(
        "Failed to remove profile picture. Please try again"
      );
      console.log("Failed to remove the picture", error);
    } finally {
      setIsLoading(false); // Hide loading state after the request completes
    }
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      
      <ProfileImageModal
        removeModal={() => setImageModal(false)}
        showModal={imageModal}
        title="Profile photo"
      >
        {/* UPLOAD FROM GALLERY */}
        <label>
          <input
            type="file"
            hidden
            accept="image/png, image/gif, image/jpeg"
            onChange={changeImageHander}
          />
          <div className="py-3 flex gap-x-1 items-center text-[#484442] mb-3">
            <img src="/gallery.png" alt="gallery" />
            <p className=" text-sm">Choose Photo from gallery</p>
          </div>
        </label>

        {/* UPLOAD FROM CAMERA */}
        {!useCamera ? (
          photo ? (
            // Display the captured photo
            <div>
              <h2>Captured Photo:</h2>
              <img src={typeof photo === "string" ? photo : URL.createObjectURL(photo)} alt="Captured" />
              <button
                className="mt-3 px-4 py-2 bg-primary text-white rounded"
                onClick={() => {
                  setPhoto(null); // Clear the captured photo
                  setUseCamera(true); // Re-enable the camera for a new photo
                }}
              >
                Retake Photo{" "}
              </button>{" "}
              <button onClick = {choosePhoto} className="mt-3 px-4 py-2 bg-primary text-white rounded">
                {" "}
                
                Choose Photo
              </button>{" "}
              {/* Button to retake the photo */}
            </div>
          ) : (
            <div
              className="py-3 flex gap-x-1 items-center text-[#484442] mb-3"
              onClick={() => setUseCamera(true)} // Open camera on click
            >
              <img src="/camera.png" alt="camera" />
              <p className=" text-sm">Take a new photo</p>
            </div>
          )
        ) : (
          // Camera view and capture button
          <div className="py-3 flex flex-col items-center text-[#484442] mb-3">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={320}
              height={240}
            />
            <button
              className="mt-3 px-4 py-2 bg-primary text-white rounded"
              onClick={capturePhoto} // Capture and save photo
            >
              Capture Photo
            </button>
          </div>
        )}

        {/* DELETE PHOTO */}
        <div
          className="py-3 flex gap-x-1 items-center text-[#484442] mb-3"
          onClick={removeImageHandler}
        >
          <img src="/profile-trash.png" alt="delete" />
          <p className=" text-sm">Delete profile photo</p>
        </div>
      </ProfileImageModal>
      <div className="h-screen overflow-hidden bg-neutral-50 dark:bg-dark-100">
        <div className="h-full mt-5">
          <div className="flex justify-between items-center mx-[20px]">
            <div className="flex-[0.3]">
              <button
                className="h-10 text-neutral-300 flex justify-center items-center"
                onClick={() => handleStepChange()}
              >
                <ChevronLeftIcon className="h-6 w-6 font-bold" />
              </button>
            </div>
            <div className="flex-[0.7]">
              <h4 className="text-header-4">Personal</h4>
            </div>
          </div>

          {isLoading ? (
            <div className="mt-5">
              <div className="animate-pulse">
                <div className="flex justify-center items-center">
                  <div className="w-[120px] h-[120px] rounded-[32px] bg-gray-300" />
                </div>
              </div>

              <div className="mt-5 border-b border-neutral-100 animate-pulse">
                <div className="flex gap-3 items-center pb-3 mx-[20px]">
                  <div className="w-[20px] h-[20px] bg-gray-300" />
                  <div className="bg-gray-300 w-[80%] h-[20px]" />
                </div>
              </div>
              <div className="mt-5 border-b border-neutral-100 animate-pulse">
                <div className="flex gap-3 items-center pb-3 mx-[20px]">
                  <div className="w-[20px] h-[20px] bg-gray-300" />
                  <div className="bg-gray-300 w-[80%] h-[20px]" />
                </div>
              </div>
              <div className="mt-5 border-b border-neutral-100 animate-pulse">
                <div className="flex gap-3 items-center pb-3 mx-[20px]">
                  <div className="w-[20px] h-[20px] bg-gray-300" />
                  <div className="bg-gray-300 w-[80%] h-[20px]" />
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-5 h-screen overflow-y-auto">
              <div className="flex justify-center items-center ">
                <div className="w-[120px] h-[120px]">
                  {userPersonal?.image === "" ? (
                    // <ImgCrop rotationSlider>
                    <Upload
                      name="avatar"
                      listType="picture-circle"
                      // className='avatar-uploader'
                      action={handleAction}
                      beforeUpload={beforeUpload}
                      onChange={handleUploadChange}
                    >
                      {imageUrl !== "" ? (
                        <div className="w-[120px] h-[120px] rounded-3xl border border-gray-400 relative">
                          <img
                            src={imageUrl}
                            alt="avatar"
                            className="w-full h-full rounded-3xl"
                          />
                        </div>
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  ) : (
                    <div className="w-[120px] h-[120px] rounded-3xl border border-gray-400 relative">
                      <img
                        src={userPersonal?.image}
                        alt="dp"
                        className="w-full h-full rounded-3xl"
                      />
                      <img
                        src="/edit-image.png"
                        alt="edit"
                        className="absolute -bottom-2 -right-3 z-10 cursor-pointer"
                        onClick={() => setImageModal(true)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="border-t border-neutral-100 mt-5 border-b pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Name</div>
                    <div>
                      <div className="text-caption py-2 ml-1">First name</div>
                      <input
                        onChange={(e) => setFirstName(e.target.value)}
                        defaultValue={userPersonal?.first_name}
                        className="input-container w-full text-2xl"
                      />
                    </div>
                    <div>
                      <div className="text-caption py-2 ml-1">Middle name</div>
                      <input
                        onChange={(e) => setMiddleName(e.target.value)}
                        defaultValue={userPersonal?.middle_name}
                        className="input-container w-full text-2xl"
                      />
                    </div>
                    <div>
                      <div className="text-caption py-2 ml-1">Last name</div>
                      <input
                        onChange={(e) => setLastName(e.target.value)}
                        defaultValue={userPersonal?.last_name}
                        className="input-container w-full text-2xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" border-neutral-100 mt-5 border-b pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Gender</div>
                    <div className="flex flex-col">
                      <Radio
                        name="gender"
                        defaultChecked={gender === "M" ? true : false}
                        placeholder=""
                        color="deep-orange"
                        label="Male"
                        value="M"
                        crossOrigin=""
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      />

                      <Radio
                        name="gender"
                        defaultChecked={gender === "F" ? true : false}
                        placeholder=""
                        color="deep-orange"
                        label="Female"
                        value="F"
                        crossOrigin=""
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      />

                      <Radio
                        name="gender"
                        placeholder=""
                        defaultChecked={gender === "O" ? true : false}
                        color="deep-orange"
                        label="Prefer not to say"
                        crossOrigin=""
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                        value="O"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" border-neutral-100 mt-5 border-b pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Date of birth</div>
                    <div>
                      <div className="text-caption py-2 ml-1">
                        Date of birth
                      </div>
                      {/* <input
                        onChange={(e) => setDob(e.target.value)}
                        defaultValue={userPersonal?.birthdate}
                        className="input-container w-full"
                        type="date"
                      /> */}
                      <Input
                        className=" h-[50px] rounded-lg border border-[#939291] text-2xl"
                        // suffix={<img src='/calendar.png' alt='calender' />}
                        type="date"
                        onChange={(e) => setDob(e.target.value)}
                        defaultValue={userPersonal?.birthdate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" border-neutral-100 mt-5 border-b pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Email address</div>
                    <div>
                      <div className="text-caption py-2 ml-1">
                        Email address
                      </div>
                      {/* <input
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={userPersonal?.email}
                        type='email'
                        className='input-container w-full'
                      /> */}
                      <Input
                        className=" h-[50px] rounded-lg border border-[#939291] text-2xl"
                        suffix={<img src="/sms.png" alt="mail" />}
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={userPersonal?.email}
                        type="email"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Number</div>
                    <div>
                      <div className="text-caption py-2 ml-1">
                        Mobile number
                      </div>
                      <div>
                        <PhoneInput
                          className="input-container  text-2xl"
                          defaultCountry="GH"
                          inputStyle={{ width: "100%" }}
                          value={userPersonal?.phone}
                          onChange={() => {}}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-evenly items-center mt-8">
                <button
                  className="text-neutral-300 font-medium"
                  onClick={() => handleStepChange()}
                >
                  Cancel
                </button>
                <button
                  className="font-semibold text-primary"
                  onClick={() => handleSubmit()}
                >
                  Save
                </button>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default EditPersonal;
