import { Fragment, useState } from 'react'
import { Spinner } from '@material-tailwind/react'
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate } from 'react-router-dom'
import ShowToast from '../../components/alerts/all_toasts'
import { StorageBox } from '../../helpers/stroage'
// import PhoneInput, { Value } from 'react-phone-number-input'
// import { Input } from 'antd'
import { RouteConstants } from '../../helpers/RouteConstants'
import Button from '../../components/Button'
import BaseService from '../../helpers/baseServices'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'

const SignInScreen = () => {
  const navigate = useNavigate()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [thePin, setThePin] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [isPhoneError, setIsPhoneError] = useState(false)
  const [isPinError, setIsPinError] = useState(false)
  const [showPin, setShowPin] = useState(false)
  const [pinLength, setPinLength] = useState<number>(0)

  //handle login
  const handleLogin = async () => {
    let formatPhoneNumber = phoneNumber?.replace(/\s+/g, '')
    if (!phoneNumber) {
      setIsPhoneError(true)
    } else if (!thePin) {
      setIsPinError(true)
    } else {
      let data = {
        phone: formatPhoneNumber,
        pin: thePin
      }
      // console.log(data)
      setIsBusy(true)
      try {
        const response = await BaseService.post_api(
          `${RouteConstants.SIGN_IN_ROUTE}`,
          data
        )
        // console.log(response);
        if (response?.status === 200) {
          ShowToast.success_toast('Login successful')
          StorageBox.saveAccessToken(response?.data?.payload?.token)
          StorageBox.saveUserData(response?.data?.payload)
          navigate('/home', { replace: true })
        }
      } catch (error: any) {
        console.error(error)
        ShowToast.error_toast(error?.response?.data?.message)
        setIsBusy(false)
      }
    }
  }

  return (
    <Fragment>
      <div className="h-screen bg-neutral-50 overflow-hidden flex flex-col">
        <div className="w-full px-[30px] mt-[40%]">
          <div className="header-text-3">Sign In</div>
          <div className="body-text-2 pt-1">Welcome back!</div>
          <div>
            <div className="flex flex-col mt-8 relative">
              <small
                className={`${isPhoneError ? "text-red-500" : ""} mb-2 ml-1`}
              >
                Your mobile number
              </small>
              {/* <PhoneInput
                className='input'
                defaultCountry='GH'
                smartCaret={false}
                placeholder='Enter your phone number'
                inputStyle={{ width: '100%' }}
                value={phoneNumber}
                onChange={(phone: any) => setPhoneNumber(phone)}
                disabled={isBusy}
                numberInputProps={{
                  className: 'focus:outline-none'
                }}
              /> */}
              <PhoneInput
                inputClassName="input"
                defaultCountry="gh"
                hideDropdown
                required
                inputProps={{
                  placeholder: "Enter your phone number",
                }}
                // placeholder="Enter your phone number"
                inputStyle={{
                  width: "100%",
                  height: "55px",
                  fontSize: "18px",
                  border: "1px solid #939291",
                  padding: "8px",
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
                value={phoneNumber}
                onChange={(phone: any) => setPhoneNumber(phone)}
                disabled={isBusy}
              />
            </div>
            <div className="flex flex-col mt-4 relative">
              <small
                className={`${isPinError ? "text-red-500" : ""} mb-2 ml-1`}
              >
                Your PIN
              </small>
              <input
                className="input"
                placeholder="Enter your pin"
                type={showPin ? "text" : "password"}
                maxLength={4}
                value={thePin}
                onChange={(e) => {
                  let value = e.target.value;
                  const trimmedValue = value.trim();
                  const numberOfCharacters = trimmedValue.length;
                  setPinLength(numberOfCharacters);

                  setThePin(e.target.value);
                }}
              />

              <div className="absolute right-2 bottom-[10px]">
                {showPin ? (
                  <button onClick={() => setShowPin(!showPin)}>
                    <EyeIcon className="w-6 h-6 text-black" />
                  </button>
                ) : (
                  <button onClick={() => setShowPin(!showPin)}>
                    <EyeSlashIcon className="w-6 h-6 text-black" />
                  </button>
                )}
              </div>
            </div>
            <div className="flex items-center justify-end mx-1 mt-1">
              {/* <button
                className='btn-subtle'
                onClick={() => navigate('/forgot-pin')}
              >
                <h3 className='text-[#F15B50] font-light'>Forgot PIN?</h3>
              </button> */}
              <div className="text-neutral-300">{pinLength}/4</div>
            </div>

            <div className="mt-[30px] flex justify-center items-center">
              <Button
                width="w-full"
                onClick={() => handleLogin()}
                disabled={isBusy}
              >
                {isBusy ? (
                  <div className="w-full flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  <span>Sign In</span>
                )}
              </Button>
            </div>

            <button
              className="btn-subtle mt-6"
              onClick={() => navigate("/forgot-pin")}
            >
              <h3 className="text-[#F15B50] font-bold text-[17px]">
                Forgot PIN?
              </h3>
            </button>
          </div>

          <div className="mt-5 flex items-center gap-3">
            <p className="text-neutral-300">Don't have an account?</p>
            <Link className="btn-subtle " to="/signup">
              Create one
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SignInScreen
