import { useState, useEffect, useContext } from 'react'
import CustomNavigation from '../../components/CustomNavigation'
import SideBar from '../../components/SideBar'
import Header from '../../components/Header'
import { DrawerContext } from '../../components/Providers'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import LoadingCoupons from './LoadingCoupons'

import BaseService from '../../helpers/baseServices'
import { RouteConstants } from '../../helpers/RouteConstants'

import ChooseDrawer from './chooseDrawer'

import { useNavigate } from 'react-router-dom'

const CouponsPage = () => {
  const ctx = useContext(DrawerContext)

  const [loading, setLoading] = useState(false)
  const [openBottom, setOpenBottom] = useState(false)
  const [stats, setStats] = useState<any>({})
  const [discountStats, setDiscountStats] = useState<any>({})
  const navigate = useNavigate()

  // fetch stats
  const fetchStats = async () => {
    setLoading(true)
    try {
      const response = await BaseService.get_api(`${RouteConstants.ALL_STATS}`)
      const discountResp = await BaseService.get_api(
        `${RouteConstants.MY_DISCOUNT_STATS}`
      )
      // console.log(response)
      setStats(response.data?.payload)
      setDiscountStats(discountResp.data?.payload)
      setLoading(false)
    } catch (error) {
      // console.log(error)
    }
  }

  const showDrawer = () => {
    ctx.toggleShow(true)
  }

  const onClose = () => {
    ctx.toggleShow(false)
  }

  useEffect(() => {
    fetchStats()
  }, [])

  //console.log(stats)
  //console.log(discountStats)

  return (
    <div className='w-full  dark:bg-dark-100 dark:text-white'>
      <SideBar closeDrawer={onClose} show={ctx.show}>
        <CustomNavigation activeIndex={1} />
      </SideBar>
      <Header title='Coupons' toggleMenu={showDrawer} page='Coupons' />
      {openBottom && (
        <ChooseDrawer
          openDrawer={openBottom}
          handleOpenDrawer={() => setOpenBottom(false)}
        />
      )}
      {/* <Header title="Coupons" showExtra={false} /> */}
      {loading ? (
        <div className='mt-5'>
          {['', '', ''].map((dd, i) => (
            <LoadingCoupons key={i} />
          ))}
        </div>
      ) : (
        <div className='bg-neutral-50 relative h-screen overflow-hidden  dark:bg-dark-100 dark:text-white'>
          <div className='h-full w-full overflow-hidden'>
            {/* main content */}
            <div>
              <div className='mt-5 h-full '>
                {/* stats cards */}

                <div className='mx-5 mb-5'>
                  <button
                    className='card-small-container dark:bg-dark-100 dark:text-white w-full'
                    onClick={() => navigate('/all-coupons?q=available')}
                  >
                    <div className='text-[#484442] flex justify-between items-center w-full'>
                      <div>
                        <p className='text-left text-sm mb-1'>
                          {/* EDITING */}
                          Available coupons
                        </p>
                        <div className='flex justify-between items-center'>
                          <div className='flex items-center gap-x-1'>
                            <svg
                              width='32'
                              height='32'
                              viewBox='0 0 32 32'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M11.9998 29.3334H19.9998C26.6665 29.3334 29.3332 26.6668 29.3332 20.0001V12.0001C29.3332 5.33342 26.6665 2.66675 19.9998 2.66675H11.9998C5.33317 2.66675 2.6665 5.33342 2.6665 12.0001V20.0001C2.6665 26.6668 5.33317 29.3334 11.9998 29.3334Z'
                                stroke='#2ABB7F'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M10 16.2873L14.2873 20.5747L22.84 12'
                                stroke='#2ABB7F'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                            </svg>
                            <div className='text-left leading-4'>
                              <p className='font-medium'>
                                {stats.total_coupons_available}
                              </p>
                              <p className=' text-xs text-[#939291]'>
                                GH&#8373;{stats.total_coupons_left_amount} of
                                GH&#8373;{stats.total_coupons_available_amount}{' '}
                                available to use
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='text-neutral-300'
                        onClick={() => navigate('/all-coupons?q=available')}
                      >
                        <ChevronRightIcon className='h-5 w-5' />
                      </div>
                    </div>
                  </button>
                </div>

                <div className='mx-[20px]'>
                  <div className='grid grid-cols-2 gap-5 '>
                    <button
                      className='card-small-container py-1 dark:bg-dark-100 dark:text-white w-full'
                      onClick={() => navigate('/all-coupons?q=discounts')}
                    >
                      <div className='text-[#484442]'>
                        <p className='text-left text-sm mb-1'>
                          Discounts earned
                        </p>

                        <div className='flex items-center gap-x-1'>
                          <svg
                            width='32'
                            height='32'
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M11.9998 29.3334H19.9998C26.6665 29.3334 29.3332 26.6667 29.3332 20.0001V12.0001C29.3332 5.33341 26.6665 2.66675 19.9998 2.66675H11.9998C5.33317 2.66675 2.6665 5.33341 2.6665 12.0001V20.0001C2.6665 26.6667 5.33317 29.3334 11.9998 29.3334Z'
                              stroke='#484442'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                            <path
                              d='M18.9997 19.8799C18.4397 19.8799 17.9897 19.4299 17.9897 18.8799C17.9897 18.3299 18.4397 17.8799 18.9897 17.8799C19.5397 17.8799 19.9897 18.3299 19.9897 18.8799C19.9897 19.4299 19.5597 19.8799 18.9997 19.8799Z'
                              fill='#484442'
                            />
                            <path
                              d='M12.9997 14.8799C12.4397 14.8799 11.9897 14.4299 11.9897 13.8799C11.9897 13.3299 12.4397 12.8799 12.9897 12.8799C13.5397 12.8799 13.9897 13.3299 13.9897 13.8799C13.9897 14.4299 13.5597 14.8799 12.9997 14.8799Z'
                              fill='#484442'
                            />
                            <path
                              d='M12.6298 20.4299C12.4398 20.4299 12.2498 20.3599 12.0998 20.2099C11.8098 19.9199 11.8098 19.4399 12.0998 19.1499L18.8298 12.4199C19.1198 12.1299 19.5998 12.1299 19.8898 12.4199C20.1798 12.7099 20.1798 13.1899 19.8898 13.4799L13.1598 20.2099C13.0198 20.3599 12.8198 20.4299 12.6298 20.4299Z'
                              fill='#484442'
                            />
                          </svg>

                          <div className='text-left leading-4'>
                            <p className='font-medium'>
                              {discountStats?.total_discount_number}
                            </p>
                            <p className=' text-xs text-[#939291]'>
                              GH&#8373;{discountStats?.total_discount_amount}
                            </p>
                          </div>
                        </div>
                      </div>
                    </button>
                    {/* 2 */}
                    <button
                      className='card-small-container py-1 dark:bg-dark-100 dark:text-white w-full'
                      onClick={() => navigate('/all-coupons?q=pending')}
                    >
                      <div className='text-[#484442]'>
                        <p className='text-left text-sm mb-1'>
                          Pending payments
                        </p>

                        <div className='flex items-center gap-x-1'>
                          <svg
                            width='32'
                            height='32'
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M11.9998 29.3334H19.9998C26.6665 29.3334 29.3332 26.6667 29.3332 20.0001V12.0001C29.3332 5.33341 26.6665 2.66675 19.9998 2.66675H11.9998C5.33317 2.66675 2.6665 5.33341 2.6665 12.0001V20.0001C2.6665 26.6667 5.33317 29.3334 11.9998 29.3334Z'
                              stroke='#F15B50'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                            <path
                              d='M11.9287 15.9646C11.9287 14.5115 10.7507 13.3335 9.29761 13.3335C7.84449 13.3335 6.6665 14.5115 6.6665 15.9646C6.6665 17.4177 7.84449 18.5957 9.29761 18.5957C10.7507 18.5957 11.9287 17.4177 11.9287 15.9646Z'
                              fill='#F15B50'
                            />
                            <path
                              d='M18.0796 15.9646C18.0796 14.5115 16.9016 13.3335 15.4485 13.3335C13.9954 13.3335 12.8174 14.5115 12.8174 15.9646C12.8174 17.4177 13.9954 18.5957 15.4485 18.5957C16.9016 18.5957 18.0796 17.4177 18.0796 15.9646Z'
                              fill='#F15B50'
                            />
                            <path
                              d='M24.231 15.9646C24.231 14.5115 23.053 13.3335 21.5999 13.3335C20.1467 13.3335 18.9688 14.5115 18.9688 15.9646C18.9688 17.4177 20.1467 18.5957 21.5999 18.5957C23.053 18.5957 24.231 17.4177 24.231 15.9646Z'
                              fill='#F15B50'
                            />
                          </svg>

                          <div className='text-left leading-4'>
                            <p className='font-medium'>
                              {stats.total_coupons_pending_payments}
                            </p>
                            <p className=' text-xs text-[#939291]'>
                              GH&#8373;
                              {stats.total_coupons_pending_payments_amount}
                            </p>
                          </div>
                        </div>
                      </div>
                    </button>
                    {/* 3 */}
                    <button
                      className='card-small-container py-1 dark:bg-dark-100 dark:text-white w-full'
                      onClick={() => navigate('/all-coupons?q=used')}
                    >
                      <div className='text-[#484442]'>
                        <p className='text-left text-sm mb-1'>Used coupons</p>

                        <div className='flex items-center gap-x-1'>
                          <svg
                            width='32'
                            height='32'
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M11.9998 29.3334H19.9998C26.6665 29.3334 29.3332 26.6667 29.3332 20.0001V12.0001C29.3332 5.33341 26.6665 2.66675 19.9998 2.66675H11.9998C5.33317 2.66675 2.6665 5.33341 2.6665 12.0001V20.0001C2.6665 26.6667 5.33317 29.3334 11.9998 29.3334Z'
                              stroke='#484442'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                            <path
                              d='M9.3335 16L22.6668 16'
                              stroke='#484442'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>

                          <div className='text-left leading-4'>
                            <p className='font-medium'>
                              {stats.total_coupons_used}
                            </p>
                            <p className=' text-xs text-[#939291]'>
                              GH&#8373;{stats.total_coupons_used_amount}
                            </p>
                          </div>
                        </div>
                      </div>
                    </button>
                    {/* 4 */}
                    <button
                      className='card-small-container dark:bg-dark-100 dark:text-white w-full'
                      onClick={() => navigate('/all-coupons?q=others')}
                    >
                      <div className='text-[#484442]'>
                        <p className='text-left text-sm mb-1'>
                          Coupons for others
                        </p>

                        <div className='flex items-center gap-x-1'>
                          <svg
                            width='32'
                            height='32'
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M11.9998 29.3334H19.9998C26.6665 29.3334 29.3332 26.6668 29.3332 20.0001V12.0001C29.3332 5.33342 26.6665 2.66675 19.9998 2.66675H11.9998C5.33317 2.66675 2.6665 5.33342 2.6665 12.0001V20.0001C2.6665 26.6668 5.33317 29.3334 11.9998 29.3334Z'
                              stroke='#388BFF'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                            <path
                              d='M10 16.2873L14.2873 20.5747L22.84 12'
                              stroke='#388BFF'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>

                          <div className='text-left leading-4'>
                            <p className='font-medium'>
                              {stats.total_number_bought_for_others}
                            </p>
                            <p className=' text-xs text-[#939291]'>
                              GH&#8373;{stats.total_amount_bought_for_others}
                            </p>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CouponsPage
