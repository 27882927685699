import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Select } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import BaseService from "../../../../helpers/baseServices";
import { global_variables } from "../../../../helpers/globalVariables";
import Button from "../../../../components/Button";
import { debounce } from "lodash";
import ShowToast from "../../../../components/alerts/all_toasts";
import { Spinner } from "@material-tailwind/react";

import "react-international-phone/style.css";
import BuyOtherUsers from "../../../../components/BuyOtherUsers";
import { StorageBox } from "../../../../helpers/stroage";
import { notification, NotificationArgsProps } from "antd";
import React from "react";

type NotificationPlacement = NotificationArgsProps["placement"];
// type FuelType = { label: string; value: string };

const OtherFuel = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [users, setUsers] = useState<any>([
    {
      firstname: "",
      lastname: "",
      id: Date.now().toString(),
      nameError: false,
      phoneError: false,
      phone: "",
      busy: false,
      amount: "",
    },
  ]);

  const [allCompanies, setAllCompanies] = useState([]);
  const [fecthingCompanies, setFecthingCompanies] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [, setSelectError] = useState(false);
  const [theAmount, setTheAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [fuelType, setFuelType] = useState("");

  const [, setTypeErr] = useState(false);
  const [fetchingTypes, setFetchingTypes] = useState(false);
  const [allFuelTypes, setAllFuelTypes] = useState([]);

  const userInfo = StorageBox.retrieveUserData();
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement) => {
    api.info({
      type: "error",
      message: "Transaction Failed",
      description: "User already added / can't add yourself",
      placement,
    });
  };

  useEffect(() => {
    fetchCompanies();
    fetchFuelTypes();
  }, []);

  //fetch comapnies
  const fetchCompanies = async () => {
    setFecthingCompanies(true);
    try {
      const response = await BaseService.get_api(
        `${global_variables.get_companies}`
      );
      // console.log(response)
      setAllCompanies(response?.data?.payload?.items);
      setFecthingCompanies(false);
    } catch (error) {
      // console.log(error)
    }
  };

  //fetchFuelTypes
  const fetchFuelTypes = async () => {
    setFetchingTypes(true);
    try {
      const response = await BaseService.get_api(
        `${global_variables.get_fuel_types}`
      );
      // console.log(response?.data)
      setAllFuelTypes(response?.data?.payload?.items);
      setFetchingTypes(false);
    } catch (error) {
      console.log(error);
    }
  };

  //handle on company change
  const onCompanyChange = (selected: any) => {
    // console.log(selected)
    setSelectedCompany(selected);
  };

  // handleOnPhoneChange
  const phoneHandler = debounce((pho: string, id: string) => {
    const usersCoppy = [...users];
    let formatPhoneNumber = pho?.replace(/\s+/g, "");

    if (formatPhoneNumber === userInfo?.user?.phone) {
      return openNotification("topRight");
    }

    let data = {
      phone: formatPhoneNumber,
    };

    if (pho && pho.length >= 10) {
      setIsBusy(true);
      BaseService.post_api_auth(`${global_variables.find_user_by_phone}`, data)
        .then((response) => {
          const varifiedUsers = usersCoppy.map((user) =>
            user.id === id
              ? {
                  ...user,
                  firstname: response?.data?.payload?.first_name,
                  lastname: response?.data?.payload?.last_name,
                  phone: formatPhoneNumber,
                }
              : user
          );
          setUsers(varifiedUsers);
          setIsBusy(false);
        })
        .catch((error) => {
          // console.log(error)
          ShowToast.error_toast(
            error?.response?.data?.message || error?.message
          );
          const errorUsers = usersCoppy.map((user) =>
            user.id === id
              ? {
                  ...user,
                  firstname: "",
                  lastname: "",
                  phoneError: true,
                }
              : user
          );
          setUsers(errorUsers);
          setIsBusy(false);
        });
    }
  }, 2000);

  //handle continue
  const handleContinue = () => {
    const usersCopy = [...users];
    let dataForPayment: string;
    if (usersCopy.length > 1) {
      navigate("/other-payment");
    } else {
      if (!selectedCompany) {
        setSelectError(true);
      } else if (!fuelType) {
        setTypeErr(true);
      } else if (!theAmount) {
        setAmountError(true);
      } else {
        dataForPayment = JSON.stringify({
          price: parseFloat(theAmount).toFixed(2),
          type: "OTHERS",
          fuel_type_code: fuelType,
          company_code: selectedCompany,
          phone: usersCopy[0].phone,
          first_name: usersCopy[0].firstname,
          last_name: usersCopy[0].lastname,
        });
        localStorage.setItem("dataForOtherPayment", dataForPayment);
        navigate("/other-payment");
        // console.log(dataForPayment)
      }
    }
  };

  const addUserHandler = () => {
    const usersCopy = [...users];
    setUsers([
      ...usersCopy,
      {
        firstname: "",
        lastname: "",
        id: Date.now().toString(),
        nameError: false,
        phoneError: false,
        phone: "",
        busy: false,
      },
    ]);
  };

  const removeUserHandler = (id: string) => {
    const usersCopy = [...users];
    const filteredUsers = usersCopy.filter((user) => user.id !== id);
    setUsers(filteredUsers);
  };

  const continueStepOne = () => {
    let detailsError = false;
    const usersCoppy = [...users];
    if (usersCoppy.length > 1) {
      usersCoppy.forEach((user) => {
        if (
          user.firstname === "" ||
          user.lastname === "" ||
          user.amount === ""
        ) {
          detailsError = true;
        }
      });
    } else {
      usersCoppy.forEach((user) => {
        if (user.firstname === "" || user.lastname === "") {
          detailsError = true;
        }
      });
    }

    if (detailsError) {
      ShowToast.error_toast("User Details Error");
    } else {
      // if (usersCoppy.length > 1) {
      //   setStep(3)
      // } else {
      //   setStep(2)
      // }
      setStep(2);
    }
  };

  const userAmountHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    const usersCopy = [...users];

    let modifiedUsers = [];

    modifiedUsers = usersCopy.map((user) =>
      user.id === id
        ? {
            ...user,
            amount: parseFloat(e.target.value) >= 0 ? e.target.value : "",
          }
        : user
    );
    setUsers(modifiedUsers);
  };

  let multipleUsersAmount: number = 0;
  if (users?.length > 1) {
    users.forEach((user: any) => {
      multipleUsersAmount += parseFloat(user?.amount);
    });
  }

  const handleTheAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regExp = /^[+]?((\d+(\.\d*)?)|(\.\d+))*$/;
    //console.log(e.target.value)
    if (regExp.test(e.target.value)) {
      setTheAmount(parseFloat(e.target.value) >= 0 ? e.target.value : "");
    }
  };

  let totalAmountForOthers = 0;

  return (
    <>
      {contextHolder}
      <div
        className={`h-screen ${users.length > 1 ? "overflow-scroll" : "overflow-hidden"} bg-neutral-50 dark:bg-dark-100`}
      >
        <div className="h-full mx-[20px] mt-5">
          {step === 1 && (
            <>
              <div className="flex justify-between items-center">
                <div className="flex-[0.3]">
                  <button
                    className="h-10 text-neutral-300 flex justify-center items-center"
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeftIcon className="h-6 w-6 font-bold" />
                  </button>
                </div>
                <div className="flex-[0.7]">
                  <h4 className="text-header-4">Buy for others</h4>
                </div>
              </div>
              {users.map((user: any, index: any) => (
                <BuyOtherUsers
                  key={user.id}
                  firstname={user.firstname}
                  lastname={user.lastname}
                  handleOnPhoneChange={(val: any) => phoneHandler(val, user.id)}
                  addUser={addUserHandler}
                  removeUser={() => removeUserHandler(user.id)}
                  nameError={user.nameError}
                  phoneError={user.nameError}
                  phoneNumber={user.phone}
                  isBusy={user.busy}
                  remove={users.length > 0 && index + 1 < users.length}
                  user={index}
                  isMultiple={users.length > 1}
                  userAmount={user.amount}
                  handleUserAmount={(e: React.ChangeEvent<HTMLInputElement>) =>
                    userAmountHandler(e, user.id)
                  }
                />
              ))}
            </>
          )}
          {step === 2 && (
            <>
              <div className="flex justify-between items-center">
                <div className="flex-[0.3]">
                  <button
                    className="h-10 text-neutral-300 flex justify-center items-center"
                    onClick={() => setStep(1)}
                  >
                    <ChevronLeftIcon className="h-6 w-6 font-bold" />
                  </button>
                </div>
                <div className="flex-[0.7]">
                  <h4 className="text-header-4">Buy for others</h4>
                </div>
              </div>
              <div className="mt-10">
                <div className="flex flex-col">
                  <small>Select fuel company</small>
                  <Select
                    placeholder="select fuel company"
                    className="h-[50px] bg-white rounded-lg border border-gray-500"
                    onChange={(val) => onCompanyChange(val)}
                    // error={selectError}
                    disabled={fecthingCompanies}
                    options={allCompanies.map((company: any, i: number) => ({
                      value: company?.code,
                      label: company?.name,
                    }))}
                  />
                </div>
                <div className="mt-5 flex flex-col">
                  <small>Select Fuel Type</small>
                  <Select
                    placeholder="Select fuel type"
                    className="h-[50px] bg-white  rounded-lg border border-gray-500"
                    onChange={(val: any) => setFuelType(val)}
                    // error={typeErr}
                    disabled={fetchingTypes}
                    options={allFuelTypes.map((item: any, i: number) => ({
                      value: item?.code,
                      label: item?.name,
                    }))}
                  />
                </div>
                <div className="mt-5">
                  <small className={`${amountError ? "text-red-500" : ""}`}>
                    Amount to buy
                  </small>
                  <input
                    className="input-container w-full focus:outline-none"
                    style={{ color: "black" }}
                    placeholder="Enter amount"
                    // type='number'
                    value={
                      users.length > 1
                        ? parseFloat(multipleUsersAmount.toString()).toFixed(2)
                        : theAmount
                    }
                    onChange={handleTheAmount}
                    disabled={users.length > 1}
                  />
                </div>
              </div>
            </>
          )}

          {/* Continue Step Three */}
          {step === 3 && (
            <>
              <div className="flex justify-between items-center mb-6">
                <div className="flex-[0.3]">
                  <button
                    className="h-10 text-neutral-300 flex justify-center items-center"
                    onClick={() => setStep(2)}
                  >
                    <ChevronLeftIcon className="h-6 w-6 font-bold" />
                  </button>
                </div>
                <div className="flex-[0.7] text-neutral-300 ">
                  <h4 className="text-header-4">Buy for others</h4>
                </div>
              </div>
              <div>
                <p className="font-bold">
                  Purchase coupon{users.length > 1 && "s"} for
                </p>
              </div>
              {users?.map((user: any, index: number) => {
                totalAmountForOthers += parseFloat(user.amount);
                return (
                  <div className="mt-10 text-[#484442] mb-5">
                    <p className="flex justify-between items-center mb-2">
                      <span className=" font-semibold">User {index + 1}</span>
                      <span
                        className="text-red-500 cursor-pointer"
                        onClick={() => setStep(1)}
                      >
                        Edit
                      </span>
                    </p>
                    <div className="flex justify-between mb-3">
                      <div>
                        <p>
                          {user.firstname} {user.lastname}
                        </p>
                        <p>{user.phone}</p>
                        <p>
                          {(
                            fuelType.charAt(0) + fuelType.toLowerCase().slice(1)
                          )
                            .replace("_", "-")
                            .replace("V-power", "V-Power")}
                        </p>
                      </div>
                      {users?.length > 1 ? (
                        <p>GH&#8373;{parseFloat(user.amount).toFixed(2)}</p>
                      ) : (
                        <p>GH&#8373;{parseFloat(theAmount).toFixed(2)}</p>
                      )}
                    </div>
                    <hr className=" border border-gray-500" />
                    {index === users.length - 1 && (
                      <div className="flex justify-between items-center mt-3">
                        <p className="font-bold">Total</p>
                        <p className="font-bold">
                          GH&#8373;
                          {users?.length > 1
                            ? totalAmountForOthers.toFixed(2)
                            : parseFloat(theAmount).toFixed(2)}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}

          <div className="mt-[50px] flex justify-center items-center">
            {step === 1 && (
              <Button width="w-full" onClick={continueStepOne}>
                {isBusy ? (
                  <div className="w-full flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  <span>Continue</span>
                )}
              </Button>
            )}
            {step === 2 && (
              <Button
                width="w-full"
                onClick={() => {
                  if (users.length > 1) {
                    const usersCopy = [...users];
                    const dataForPayment = JSON.stringify({
                      price: parseFloat(multipleUsersAmount.toString()).toFixed(
                        2
                      ),
                      type: "OTHERS",
                      fuel_type_code: fuelType,
                      company_code: selectedCompany,
                      users: usersCopy,
                    });
                    localStorage.setItem("dataForOtherPayment", dataForPayment);

                    setStep(3);
                  } else {
                    setStep(3);
                  }
                }}
              >
                Continue
              </Button>
            )}
            {step === 3 && (
              <Button
                width="w-full"
                onClick={() => {
                  handleContinue();
                }}
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherFuel;
