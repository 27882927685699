// import { useState, useEffect } from 'react'
// import { RouteConstants } from '../helpers/RouteConstants'
// import { User } from '../types'
import { useNavigate } from 'react-router-dom'
// import BaseService from '../helpers/baseServices'
// import { ThemeContext } from './Providers'
// import { useContext } from 'react'

type HeaderProps = {
  title: string
  // showExtra?: boolean
  toggleMenu: () => void
  page: 'Coupons' | 'Home' | 'Settings' | 'Regions'
}

const Header = ({
  title,
  toggleMenu,
  page
  // showExtra = false
  // children
}: HeaderProps) => {
  // const [userPersonal, setPersonalData] = useState<User | null>()
  // const [isLoading, setIsLoading] = useState(false)
  // const [notificationEvents, setNotificationEvents] = useState<any>([])

  const navigate = useNavigate()

  // const getUserData = async () => {
  //   setIsLoading(true)
  //   try {
  //     const res = await BaseService.account_ms_get_request(`/v1/profile`)

  //     setPersonalData(res.data?.payload)
  //     setIsLoading(false)
  //     //return res;
  //   } catch (e) {
  //     setIsLoading(false)
  //   }
  // }
  // const getUserNotifications = async () => {
  //   setIsLoading(true)

  //   try {
  //     const res = await BaseService.get_api(
  //       `${RouteConstants.MY_COUPON_EVENTS}`
  //     )

  //     setNotificationEvents(res.data?.payload?.items)
  //     setIsLoading(false)
  //     //return res;
  //   } catch (e) {
  //     setIsLoading(false)
  //   }
  // }

  // useEffect(() => {
  //   getUserData()
  //   // getUserNotifications()
  // }, [])
  //const darkctx = useContext(ThemeContext)
  return (
    <div className="header px-5 py-[10px] dark:bg-dark-100 dark:text-white flex justify-between items-center">
      <div
        className="font-bold text-lg text-neutral-300 flex items-center gap-x-3 cursor-pointer"
        onClick={toggleMenu}
      >
        <img src="/menu.png" alt="menu" />
        <span>{title}</span>
      </div>

      {/* <div className='flex flex-row items-center space-x-3'>
        <img src="/notification.png"></img>
        <img className="w-[24px] h-[24px]" src="/profilepic.png"></img>
      </div> */}

      {page === "Coupons" && (
        <div
          className="flex gap-x-1 items-center cursor-pointer"
          onClick={() => navigate("/all-coupons?q=archive")}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 6.81323V12.6666C13 13.9999 12.6667 14.6666 11 14.6666H5C3.33333 14.6666 3 13.9999 3 12.6666V6.81323"
              stroke="#A53D00"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.3335 1.33325H12.6668C14.0002 1.33325 14.6668 1.99992 14.6668 3.33325V4.66659C14.6668 5.99992 14.0002 6.66658 12.6668 6.66658H3.3335C2.00016 6.66658 1.3335 5.99992 1.3335 4.66659V3.33325C1.3335 1.99992 2.00016 1.33325 3.3335 1.33325Z"
              stroke="#A53D00"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.78662 9.33325H9.21329"
              stroke="#A53D00"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className=" text-[#A53D00] font-medium">Archived</p>
        </div>
      )}
    </div>
  );
}

export default Header
