import { useContext ,useState } from 'react'
import { DrawerContext } from '../../components/Providers'
import CustomNavigation from '../../components/CustomNavigation'
import CouponsScreen from './coupons'
// import UserWallet from '../../components/userWallet'
import FLoatingAction from '../../components/floating-action'
import Header from '../../components/Header'
import SideBar from '../../components/SideBar'

const HomeScreen = () => {
  const ctx = useContext(DrawerContext)

  const showDrawer = () => {
    ctx.toggleShow(true)
  }

  const onClose = () => {
    ctx.toggleShow(false)
  }

 
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
       setIsVisible(false);
    };
  

  return (
    <div className="w-full dark:bg-dark-100 dark:text-white">
      <SideBar closeDrawer={onClose} show={ctx.show}>
        <CustomNavigation activeIndex={0} />
      </SideBar>

      <Header title="Home" toggleMenu={showDrawer} page="Home" />

      {isVisible && (
        <div className="w-[363px] h-[83px] p-3 bg-gradient-to-r from-[#ff0800] via-[#ff2919] to-[#ffb080] rounded-lg flex-col justify-start items-start gap-1 inline-flex mt-4 ml-[38px]">
          <div className="self-stretch justify-between items-center inline-flex">
            <div className="text-white text-xs font-semibold font-['IBM Plex Sans'] leading-[14px]">
              Have bits of available coupons?
            </div>
            <button className="w-5 h-5 relative" onClick={handleClose}>
              <img src="/white-close.png" alt="Close" />
            </button>
          </div>
          <div className="self-stretch justify-start items-center gap-3.5 inline-flex">
            <div className="w-[226px] text-white text-[11px] font-normal font-['IBM Plex Sans'] leading-[14px]">
              Merge bits of available coupons into a single coupon for ease of
              use at the fuel station
            </div>
            <img src="/mergenotif.png" alt="Notification Icon" />
          </div>
        </div>
      )}

      <div className="bg-neutral-50 relative h-screen overflow-hidden px-5 dark:bg-dark-100 dark:text-white">
        {/* <UserWallet /> */}
        {/* <Wallet></Wallet> */}

        <div className="h-full w-full overflow-hidden">
          {/* main content */}
          <div className="h-screen overflow-y-auto">
            <CouponsScreen />
          </div>
        </div>
        <FLoatingAction />
      </div>
    </div>
  );
}

export default HomeScreen;



